import { Avatar, Box, Button, Checkbox, createTheme, CssBaseline, FormControl, FormHelperText, Grid, InputLabel, Link, MenuItem, Modal, Paper, Select, TextField, ThemeProvider, Typography } from "@mui/material";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { useRef, useState } from "react";
import { MuiTelInput } from "mui-tel-input";
import axios from "axios";
import config from "../config";
import { useNavigate } from "react-router-dom";
import SignatureCanvas from 'react-signature-canvas'

const defaultTheme = createTheme();

const Copyright = (props) => {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://elancelearning.com/">
                Elance Learning
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '100%',
        md: '30%'
    },
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    maxHeight: '80%',
};

const RegistrationForm = () => {
    const [formData, setFormData] = useState({
        firstName: '',
		lastName: '',
		phoneNumber: '+91',
		parentNumber: '+91',
        whatsappNumber: '+91',
		email: '',
        gender: '',
		address: '',
		country: '',
		state: '',
		city: '',
		pinCode : '',
		qualification: '',
        studentSign: null,
        parentSign: null,
        agreed: false,
    });
    const [errors, setErrors] = useState({
		firstName: '',
		lastName: '',
		phoneNumber: '',
		parentNumber: '',
		whatsappNumber: '',
        email: '',
		address: '',
		country: '',
		state: '',
		city: '',
		pinCode : '',
		qualification: '',
        studentSign: '',
        parentSign: '',
        agreed: '',
	});
    const [signatureModal, setSignatureModal] = useState(false);
    const [signIsParent, setSignIsParent] = useState(false);
    const [signIsStudent, setSignIsStudent] = useState(false);

    const navigate = useNavigate();
    const singatureRef = useRef();

    const validateForm = () => {
		let isValid = true;
		const newErrors = {
			firstName: '',
            lastName: '',
            phoneNumber: '',
            parentNumber: '',
            whatsappNumber: '',
            email: '',
            gender: '',
            address: '',
            country: '',
            state: '',
            city: '',
            pinCode : '',
            qualification: '',
            studentSign: '',
            parentSign: '',
            agreed: '',
		};

        if(!formData.firstName) {
            newErrors.firstName = 'First Name is required';
			isValid = false;
        }
        if(!formData.lastName) {
            newErrors.lastName = 'Last Name is required';
			isValid = false;
        }
        if(!formData.email) {
            newErrors.email = 'Email is required';
			isValid = false;
        }
        if(!formData.gender) {
            newErrors.gender = 'Gender is required';
			isValid = false;
        }
        if(!formData.address) {
            newErrors.address = 'Email is required';
			isValid = false;
        }
        if(!formData.country) {
            newErrors.country = 'Country is required';
			isValid = false;
        }
        if(!formData.state) {
            newErrors.state = 'State is required';
			isValid = false;
        }
        if(!formData.city) {
            newErrors.city = 'City is required';
			isValid = false;
        }
        if(!formData.pinCode) {
            newErrors.pinCode = 'Pin Code is required';
			isValid = false;
        }
        if(!formData.qualification) {
            newErrors.qualification = 'Qualification is required';
			isValid = false;
        }

        if(!formData.phoneNumber) {
            newErrors.phoneNumber = 'Phone number is required';
			isValid = false;
        }
		else if (!validatePhoneNumber(formData.phoneNumber)) {
			newErrors.phoneNumber = 'Invalid phone number';
			isValid = false;
		}

		if(!formData.parentNumber) {
            newErrors.parentNumber = 'Parent number is required';
			isValid = false;
        }
		else if (!validatePhoneNumber(formData.parentNumber)) {
			newErrors.parentNumber = 'Invalid parent number';
			isValid = false;
		}

        if(!formData.whatsappNumber) {
            newErrors.whatsappNumber = 'Whatsapp number is required';
			isValid = false;
        }
		else if (!validatePhoneNumber(formData.whatsappNumber)) {
			newErrors.whatsappNumber = 'Invalid whatsapp number';
			isValid = false;
		}
        
        if(!formData.studentSign) {
            newErrors.studentSign = 'Student Signature is required';
			isValid = false;
        }

        if(!formData.parentSign) {
            newErrors.parentSign = 'Parent Signature is required';
			isValid = false;
        }

        if(!formData.agreed) {
            newErrors.agreed = `Accept the Elance Learning's terms and conditions`;
			isValid = false;
        }

		setErrors(newErrors);
		return isValid;
	};

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            let murl = `${config.apiUrl}/student/register`;
			try {
				
                const newFormData = new FormData();
                newFormData.append('firstName', formData.firstName);
                newFormData.append('lastName', formData.lastName);
                newFormData.append('phoneNumber', formData.phoneNumber);
                newFormData.append('parentNumber', formData.parentNumber);
                newFormData.append('whatsappNumber', formData.whatsappNumber);
                newFormData.append('email', formData.email);
                newFormData.append('gender', formData.gender);
                newFormData.append('address', formData.address);
                newFormData.append('country', formData.country);
                newFormData.append('state', formData.state);
                newFormData.append('city', formData.city);
                newFormData.append('pinCode', formData.pinCode);
                newFormData.append('qualification', formData.qualification);
                newFormData.append('studentSign', dataURLToBlob(formData.studentSign), 'student-sign.png');
                newFormData.append('parentSign', dataURLToBlob(formData.parentSign), 'parent-sign.png');

				const response = await axios.post(murl, newFormData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				});
				
				alert('Your registration is complete. Our team will reach out to you soon!');
                navigate('/student');
				
			} catch (error) {
				alert(error?.response?.data?.error);
				console.error(error);
			}
        }
    }

    const dataURLToBlob = (dataURL) => {
        const [header, base64] = dataURL.split(',');
        const mime = header.match(/:(.*?);/)[1];
        const binary = atob(base64);
        const array = [];
        for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], { type: mime });
    }

    const handleChange = (e) => {
		const { name, value } = e.target;

		if(name === 'firstName' || name === 'lastName'){
			if(value && !/^[A-Za-z ]+$/.test(value)){
				alert('Accept only letters and spaces!');
			}
			else{
				setFormData((prevData) => ({
					...prevData,
					[name]: value,
				}));
			}
		}
		else {
			setFormData((prevData) => ({
				...prevData,
				[name]: value,
			}));
		}

        setErrors((prevData) => ({
            ...prevData,
            [name]: '',
        }));
	}

    const handlePhoneChange = (value) => {
		setFormData((prevData) => ({
			...prevData,
			phoneNumber: value,
		}));

        setErrors((prevData) => ({
            ...prevData,
            phoneNumber: '',
        }));

		if(validatePhoneNumber(value)){
			handlePhoneDuplicateCheck('student', value);
		}
	}

    const handleWhatsappNumberChange = (value) => {
		setFormData((prevData) => ({
			...prevData,
			whatsappNumber: value,
		}));

        setErrors((prevData) => ({
            ...prevData,
            whatsappNumber: '',
        }));

		if(validatePhoneNumber(value)){
			handlePhoneDuplicateCheck('whatsapp', value);
		}
	}

	const handleParentPhoneChange = (value) => {
		setFormData((prevData) => ({
			...prevData,
			parentNumber: value,
		}));

        setErrors((prevData) => ({
            ...prevData,
            parentNumber: '',
        }));

		if(validatePhoneNumber(value)){
			handlePhoneDuplicateCheck('parent', value);
		}
	}

    const handlePhoneDuplicateCheck = async (type, number) => {
		try {
		    let data = {};
            let phoneType = '';
            if(type === 'student'){
                phoneType = 'phoneNumber'
                data = {
                    phoneNumber: number,
                }
            }
            else if(type === 'parent'){
                phoneType = 'parentNumber'
                data = {
                    parentNumber: number,
                }
            }
            else {
                phoneType = 'whatsappNumber';
                data = {
                    whatsappNumber: number,
                }
            }
            
            const response = await axios.post(`${config.apiUrl}/student/checkStudentPhoneExist`, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if(response?.data?.status === 'error' && response?.data?.studentPhone === true){
                alert(response?.data?.message);
                setErrors((prevError) => ({
                    ...prevError,
                    [phoneType]: response?.data?.message
                }))
                setFormData({
                    ...formData,
                    phoneNumber: '+91',
                });
            }
            else if(response?.data?.status === 'error'){
                alert(response?.data?.message);
            }
		}
		catch(error) {
			console.error(error);
		}
	}

	const validatePhoneNumber = (phoneNumber) => {
		const regex = /^\+\d{1,3}\s\d{5}\s\d{5}$/;
		const regex2 = /^\+\d{1,3}\d{5}\d{5}$/;
		const regex3 = /^\+\d{1,3}\s\d{4}\s\d{4}$/;
		const regex4 = /^\+\d{1,3}\d{4}\d{4}$/;
		return regex.test(phoneNumber) || regex2.test(phoneNumber.replace(/\s/g, '')) || regex3.test(phoneNumber) || regex4.test(phoneNumber);
	}

    const parentSignTrigger = () => {
        setSignatureModal(true);
        setSignIsParent(true);
    }

    const studentSignTrigger = () => {
        setSignatureModal(true);
        setSignIsStudent(true);
    }

    const trimmeCanvas = () => {
        const imageData = singatureRef.current.getTrimmedCanvas().toDataURL('image/png');
        if(signIsParent) {
            setFormData((prevData) => ({
                ...prevData,
                parentSign: imageData,
            }));
            setSignIsParent(false);
            setSignatureModal(false);
        }

        if(signIsStudent) {
            setFormData((prevData) => ({
                ...prevData,
                studentSign: imageData,
            }));
            setSignIsStudent(false);
            setSignatureModal(false);
        }
    }

    const termsChange = (e) => {
        console.log(e.target.checked);
        
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ marginTop: '20px', paddingBottom: '20px', height: '100vh', display:'flex', justifyContent:'center', alignItems:'center' }}>
                <CssBaseline />
                <Grid item xs={11} sm={6} md={4} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            borderRadius:8
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <AppRegistrationIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">Register</Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}> 
                            <TextField
                                label="First Name"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                fullWidth
                                required
                                margin="normal"
                                error={Boolean(errors.firstName)}
                                helperText={errors.firstName}
                            />
                            <TextField
                                label="Last Name"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                fullWidth
                                required
                                margin="normal"
                                error={Boolean(errors.lastName)}
                                helperText={errors.lastName}
                            />
                            <MuiTelInput
                                label="Phone Number"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handlePhoneChange}
                                fullWidth
                                required
                                error={Boolean(errors.phoneNumber)}
                                helperText={errors.phoneNumber}
                                margin="normal"
                            />
                            <MuiTelInput 
                                label="Whatsapp Number"
                                name="whatsappNumber"
                                value={formData.whatsappNumber}
                                onChange={handleWhatsappNumberChange}
                                fullWidth
                                required
                                error={Boolean(errors.whatsappNumber)}
                                helperText={errors.whatsappNumber}
                                margin="normal"
                            />
                            <MuiTelInput 
                                label="Parent Number"
                                name="parentNumber"
                                value={formData.parentNumber}
                                onChange={handleParentPhoneChange}
                                fullWidth
                                required
                                error={Boolean(errors.parentNumber)}
                                helperText={errors.parentNumber}
                                margin="normal"
                            />
                            <TextField
                                label="Email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                fullWidth
                                required
                                type="email"
                                margin="normal"
                                error={Boolean(errors.email)}
                                helperText={errors.email}
                            />
                            <FormControl fullWidth style={{ marginTop: '20px'}} error={Boolean(errors.gender)}>
                                <InputLabel id="Gender">Gender</InputLabel>
                                <Select
                                    label="Gender"
                                    name="gender"
                                    value={formData.gender}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                >
                                    <MenuItem value="male">Male</MenuItem>
                                    <MenuItem value="female">Female</MenuItem>
                                    <MenuItem value="other">Other</MenuItem>
                                </Select>
                                {errors.gender ? <FormHelperText>{errors.gender}</FormHelperText> :''}
                            </FormControl>
                            <TextField
                                label="Address"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                                fullWidth
                                required
                                margin="normal"
                                error={Boolean(errors.address)}
                                helperText={errors.address}
                            />
                            <TextField
                                label="Country"
                                name="country"
                                value={formData.country}
                                onChange={handleChange}
                                fullWidth
                                required
                                margin="normal"
                                error={Boolean(errors.country)}
                                helperText={errors.country}
                            />
                            <TextField
                                label="State"
                                name="state"
                                value={formData.state}
                                onChange={handleChange}
                                fullWidth
                                required
                                margin="normal"
                                error={Boolean(errors.state)}
                                helperText={errors.state}
                            />
                            <TextField
                                label="City"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                                fullWidth
                                required
                                margin="normal"
                                error={Boolean(errors.city)}
                                helperText={errors.city}
                            />
                            <TextField
                                label="Pin code"
                                name="pinCode"
                                value={formData.pinCode}
                                onChange={handleChange}
                                fullWidth
                                required
                                margin="normal"
                                error={Boolean(errors.pinCode)}
                                helperText={errors.pinCode}
                            />
                            <TextField
                                label="Qualification"
                                name="qualification"
                                value={formData.qualification}
                                onChange={handleChange}
                                fullWidth
                                required
                                margin="normal"
                                error={Boolean(errors.qualification)}
                                helperText={errors.qualification}
                            />

                            <div>
                                <Checkbox onChange={termsChange}/> I Agree to <a color="inherit" href="https://elancelearning.com/" target="_blank">{`Elance Learning's `}</a> <a href="https://elancelearning.com/student-policy/" target="_blank"> Terms and Conditions.</a>
                                {errors.agreed ? <FormHelperText error={true}>{errors.agreed}</FormHelperText> : ''}
                            </div>
                            
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} md={6}>
                                    {formData.parentSign && 
                                    <img
                                        src={formData.parentSign}
                                        alt="parent sign"
                                        loading="lazy"
                                        style={{ maxHeight: '100px'}}
                                    />}
                                    <Button
                                        type="button"
                                        fullWidth
                                        variant="outlined"
                                        sx={{ mt: 3, mb: 2 }}
                                        onClick={parentSignTrigger}
                                    >
                                        Parent Sign
                                    </Button>
                                    {errors.parentSign ? <FormHelperText error={true}>{errors.parentSign}</FormHelperText> :''}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {formData.studentSign && 
                                    <img
                                        src={formData.studentSign}
                                        alt="parent sign"
                                        loading="lazy"
                                        style={{ maxHeight: '100px' }}
                                    />}
                                    <Button
                                        type="button"
                                        fullWidth
                                        variant="outlined"
                                        sx={{ mt: 3, mb: 2 }}
                                        onClick={studentSignTrigger}
                                    >
                                        Student Sign
                                    </Button>
                                    {errors.studentSign ? <FormHelperText error={true}>{errors.studentSign}</FormHelperText> :''}
                                </Grid>
                            </Grid>

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Register
                            </Button>
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Modal
                open={signatureModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{ border: '1px solid #000000', marginBottom: '20px' }}>
                        <SignatureCanvas ref={singatureRef} penColor='black' canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} />
                    </div>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4} md={3}>
                            <Button
                                type="button"
                                variant="contained"
                                onClick={() => {setSignatureModal(false); setSignIsParent(false); setSignIsStudent(false);}}
                            >
                                Close
                            </Button>
                        </Grid>
                        <Grid item xs={8} md={5}>
                            <Button
                                type="button"
                                variant="outlined"
                                onClick={() => {singatureRef.current.clear()}}
                                sx={{ marginRight: '10px'}}
                            >
                                Clear
                            </Button>
                            <Button
                                type="button"
                                variant="outlined"
                                onClick={() => trimmeCanvas()}
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={0} md={4}></Grid>
                    </Grid>
                </Box>
            </Modal>
        </ThemeProvider>
    );
}

export default RegistrationForm;