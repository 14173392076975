import React, { useEffect, useState } from 'react';
import { Typography, Button, TextField, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import apiService from '../../../utlis/apiService';
import { useUser } from '../../../context/userContext';

const MyTable = () => {
    const { permissions } = useUser();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalResults, setTotalResults] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [remark, setRemark] = useState('');
    const navigate = useNavigate();
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 50,
        page: 0,
    });
    const pageOption = [{label: 10, value: 10},{label: 25, value: 25},{label: 50, value: 50},{label: 100, value: 100}];
    const [openModal, setOpenModal] = useState(false);
    const [selecteSchedule, setSelecteSchedule] = useState('');

    useEffect(() => {
        getData();
    }, [searchTerm, paginationModel]);

    const getData = async () => {
        try {
            setLoading(true); // Start loading
            const response = await apiService.get('/schedule/list', {
                params: {
                    searchTerm: searchTerm,
                    page: paginationModel.page,
                    pageSize: paginationModel.pageSize,
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setData(response?.data?.schedules);
            setTotalResults(response?.data?.totalResults);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false); // Stop loading, whether success or error
        }
    };

    const deleteItem = async (id) => {
        const shouldDelete = window.confirm('Are you sure you want to delete this item?');
        if (shouldDelete) {
            try {
                await apiService.post(`/schedule/delete?id=${id}`);
                getData();
            } catch (error) {
                console.error(error);
            }
        }
    };

    const createPress = () => {
        navigate('/dashboard/schedule/add');
    };

    const createMultiplePress = () => {
        navigate('/dashboard/schedule/add/multiple');
    };

    const handleSubmitRemark = async () => {
        try {
            const response = await apiService.post(`/schedule/cancel?id=${selecteSchedule}`, {
                remark: remark
            });
            if(response.data.status === 'success') {
                setOpenModal(false);
                setRemark('');
                getData();
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                <Typography variant="h4" gutterBottom>Schedules</Typography>
                {permissions.includes('createSchedule') && (
                    <div style={{ marginBottom: '16px' }}>
                        <Button variant="contained" color="primary" style={{ marginRight: '10px', }} onClick={createPress}>
                            + Create
                        </Button>
                        <Button variant="contained" color="primary" onClick={createMultiplePress}>
                            + Create Multiple
                        </Button>
                    </div>
                )}
            </div>
            <TextField
                label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fullWidth
                margin="normal"
                style={{ marginBottom: 10 }}
            />
            {loading ? ( // Show loader if loading
                <CircularProgress style={{ margin: '20px auto', display: 'block' }} />
            ) : (
                <div style={{ height: 400, width: '100%' }}>
                    <DataGridPro
                        rows={data}
                        getRowId={(row) => row._id}
                        columns={[
                            { field: 'batch', headerName: 'Batch', flex: 1, valueGetter: (params) => params.row?.batch?.name },
                            { field: 'date', headerName: 'Year', flex: 1, valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) },
                            { field: 'status', headerName: 'Status', flex: 1, valueGetter: (params) => params.row?.status === 'active' ? 'Active' : params.row?.status === 'completed' ? 'Completed' : 'Canceled' },
                            {
                                field: 'actions',
                                headerName: 'Action',
                                flex: 1,
                                renderCell: (params) => (
                                    <>
                                        {permissions.includes('readSchedule') && (
                                            <Button variant="outlined" onClick={() => navigate(`/dashboard/schedule/view?id=${params.row._id}`)}>View</Button>
                                        )}
                                        {permissions.includes('updateSchedule') && (
                                            <>
                                                <Button variant="outlined" style={{ marginLeft: 5 }} onClick={() => navigate(`/dashboard/schedule/add?id=${params.row._id}`)}>Edit</Button>
                                                {params.row.status === 'active' ?
                                                    <Button variant="outlined" color="error" style={{ marginLeft: 5 }} onClick={() => { setOpenModal(true); setSelecteSchedule(params.row._id); }}>Cancel</Button>
                                                : ''}
                                            </>
                                        )}
                                        {permissions.includes('deleteSchedule') && (
                                            <Button variant="outlined" color="error" style={{ marginLeft: 5 }} onClick={() => deleteItem(params.row._id)}>Delete</Button>
                                        )}
                                    </>
                                ),
                            },
                        ]}
                        pagination
                        paginationModel={paginationModel}
                        rowCount={totalResults}
                        paginationMode="server"
                        components={{ Toolbar: GridToolbar }} // Add GridToolbar for filtering
                        onPaginationModelChange={setPaginationModel}
                        pageSizeOptions={pageOption} 
                    />
                </div>
            )}

            <Dialog open={openModal} onClose={() => setOpenModal(false)}>
				<DialogTitle> To cancel the schedule please enter the remark and submit</DialogTitle>
				<DialogContent style={{ minWidth: '600px', minHeight: '100px' }}>
                    <TextField
                        label="Remark"
                        name="remark"
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenModal(false)} color="primary">
						Cancel
					</Button>
					<Button
						onClick={() => handleSubmitRemark()}
						color="primary"
					>
						Submit
					</Button>
				</DialogActions>
			</Dialog>
        </div>
    );
};

export default MyTable;
